import { TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import FormControl from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";
import { useSnackbar } from "notistack";
import { makeStyles } from "@mui/styles";
import { htmlDecode } from "src/utils/convertHtml";
import { TrainRounded } from "@mui/icons-material";
import {
  AddCommentOnQuestion,
  DeleteQuestionCommentApi,
} from "src/DAL/MemberGoalStatement/MemberGoalStatement";
import CustomPopover from "src/components/CustomPopover";
import CustomConfirmationModal from "src/components/modal/CustomConfirmationModal";

export const QuestionsReply = ({ data, detailQuestionHistory }) => {
  const id = useParams();
  const { enqueueSnackbar } = useSnackbar();
  const [replies, setReplies] = useState([]);
  const [isLoading, setIsLoading] = React.useState(false);
  const [openDelete, setOpenDelete] = React.useState(false);
  const [deleteDoc, setDeleteDoc] = React.useState("");
  const [editState, setEditState] = React.useState(false);
  const [detailDescriptionCk, setDetailDescriptionCk] = React.useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(TrainRounded);
    let postData = {
      question_id: data._id,
      member_id: id.id,
      comment: detailDescriptionCk,
    };

    const result = await AddCommentOnQuestion(postData);
    if (result.code == 200) {
      setReplies((replies) => [...replies, result.comment_stats]);
      setDetailDescriptionCk("");
      enqueueSnackbar(result.message, { variant: "success" });
      detailQuestionHistory();
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
    }
    setIsLoading(false);
  };

  const handleAgreeDelete = (value) => {
    setDeleteDoc(value);
    setOpenDelete(true);
  };
  const handleDelete = async () => {
    setOpenDelete(false);
    const result = await DeleteQuestionCommentApi(deleteDoc._id);
    if (result.code === 200) {
      setReplies((replies) => {
        return replies.filter((reply) => reply._id !== deleteDoc._id);
      });
      detailQuestionHistory();
      enqueueSnackbar(result.message, { variant: "success" });
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };

  const handleChange = (event) => {
    setDetailDescriptionCk(event.target.value);
  };

  useEffect(() => {
    setReplies(data.comment);
  }, [data]);

  const MENU_OPTIONS = [
    {
      label: "Delete",
      icon: "ant-design:delete-twotone",
      handleClick: handleAgreeDelete,
    },
  ];
  return (
    <div className="container">
      <CustomConfirmationModal
        message="Are you sure you want to delete?"
        open={openDelete}
        setOpen={setOpenDelete}
        handleAgree={handleDelete}
      />
      <div className="row answer-history">
        <div className="col-12 mt-3">
          <p className="heading">Question :</p>
          <p className="mb-4">{htmlDecode(data.question)}</p>
        </div>
        {replies?.length < 1 ? (
          <h6>No Comment</h6>
        ) : (
          <>
            <p className="heading mb-2">Comments :</p>
            {replies?.map((reply) => {
              return (
                <>
                  <div className="col-10">
                    <p>{htmlDecode(reply.comment)}</p>
                  </div>

                  <div className="col-2 ms-auto text-end">
                    <CustomPopover menu={MENU_OPTIONS} data={reply} />
                  </div>
                </>
              );
            })}
          </>
        )}
      </div>
      <form onSubmit={handleSubmit}>
        <div className="row">
          <div className="col-12 mt-3">
            <FormControl fullWidth>
              <TextField
                id="outlined-multiline-flexible"
                label="Hina's Reply"
                multiline
                rows={6}
                name="short_description"
                value={detailDescriptionCk}
                onChange={handleChange}
                required
              />
              <FormHelperText>Maximum limit 500 characters</FormHelperText>
            </FormControl>
          </div>
          <div className="col-12 mb-3 text-end">
            <button className="small-contained-button" disabled={isLoading}>
              {isLoading ? "Submitting" : "Submit"}
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};
