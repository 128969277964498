import pieChart2Fill from "@iconify/icons-eva/pie-chart-2-fill";
import barchartfill from "@iconify/icons-eva/bar-chart-fill";
import { project_name } from "src/config/config";
import DateRangeIcon from "@mui/icons-material/DateRange";
import SettingsIcon from "@mui/icons-material/Settings";
import { Icon } from "@iconify/react";
import { ListItemIcon } from "@mui/material";
import personFill from "@iconify/icons-eva/person-fill";
import {
  hkDashboard,
  hkGoal_Statement,
  hkPods,
  hkProfile,
  hkVault,
} from "src/assets";
// ----------------------------------------------------------------------
const getMUIIcon = (name) => {
  return <ListItemIcon className="navbar-icon">{name}</ListItemIcon>;
};
const getImageIcon = (name) => (
  <img src={name} width={22} height={22} className="navbar-icon" />
);

const getEvaIcon = (name) => (
  <Icon className="navbar-icon" icon={name} width={22} height={22} />
);

let sidebarConfig = ({ recording_name = "", type }) => {
  const sidebarConfig = [
    {
      title: "Dashboard",
      path: "/dashboard",
      icon: getImageIcon(hkDashboard),
    },
  ];
  if (type !== "team") {
    sidebarConfig.push({
      title: "Members",
      path: "/members",
      icon: getImageIcon(hkProfile),
    });
  }
  if (type !== "team") {
    sidebarConfig.push({
      title: "Goal Statement",
      path: "/goal-statement",
      icon: getImageIcon(hkGoal_Statement),
    });
  }
  if (type !== "team") {
    sidebarConfig.push({
      title: "Member Goal Statement",
      icon: getImageIcon(hkGoal_Statement),
      child_options: [
        {
          title: "Complete",
          path: "/member-completed-goalstatements",
          icon: getImageIcon(hkGoal_Statement),
        },
        {
          title: "Incomplete",
          path: "/member-incomplete-goalstatements/",
          icon: getImageIcon(hkGoal_Statement),
        },
        {
          title: "Responded",
          path: "/member-responded-goalstatements",
          icon: getImageIcon(hkGoal_Statement),
        },
      ],
    });
  }
  if (type !== "team") {
    sidebarConfig.push({
      title: "Self Image",
      path: "/self-image",
      icon: getImageIcon(hkGoal_Statement),
    });
  }
  if (type !== "team") {
    sidebarConfig.push({
      title: `${recording_name} Recording`,
      path: "/recordings",
      icon: getImageIcon(hkVault),
    });
  }
  if (type !== "team") {
    sidebarConfig.push({
      title: "Links",
      path: "/links",
      icon: getImageIcon(hkPods),
    });
  }
  if (type !== "team") {
    sidebarConfig.push({
      title: "Calendar",
      icon: getImageIcon(hkDashboard),
      child_options: [
        {
          title: "Groups",
          path: "/groups",
          icon: getImageIcon(hkDashboard),
        },
        {
          title: "Calendar Events",
          path: "/calender",
          icon: getImageIcon(hkDashboard),
        },
      ],
    });
  }
  if (type !== "consultant") {
    sidebarConfig.push({
      title: "Support Ticket",
      path: "/support-ticket",
      icon: getImageIcon(hkDashboard),
    });
  }
  return sidebarConfig;
};

export default sidebarConfig;
