import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import {
  PrivateRouteWithLayout,
  // RouteWithLayout,
  PublicRouteWithLayout,
  // PrivateRouteWithLayout,
} from "./components";
// layouts
import DashboardLayout from "./layouts/dashboard";
import LogoOnlyLayout from "./layouts/LogoOnlyLayout";
//
import {
  ForgetPassword,
  Programmes,
  ProgrammesDetail,
  GoalStatement,
  Vault,
  Profile,
  Pods,
  Affirmations,
  Gratitude,
  Quotes,
  Memories,
  MemoriesDetails,
  DailyActivities,
  Calender,
  LessonsDetail,
  LessonListing,
  ProgrammRecordingDetail,
  AffirmationList,
} from "./pages/index";
import LessonsReSource from "./pages/lessonsResource/LessonsResource";
import ReviewCards from "./pages/ReviewCard/ReviewCards";
import Login from "./pages/Login";
import Register from "./pages/Register";
import DashboardApp from "./pages/DashboardApp";
import Products from "./pages/Products";
import Blog from "./pages/Blog";
import User from "./pages/User";
import NotFound from "./pages/Page404";
import AddGratitude from "./pages/Gratitude/AddGratitude";
import MemoriesDetail from "./pages/Memories/MemoriesDetail";
import SourcesFiles from "./pages/SourceCard/SourcesFiles";
import BobVideos from "./pages/BOBVideos/BobVideos";
import LessonsNotes from "./components/LessonNotes/LessonsNotes";
import LessonsRecording from "./pages/LessonsRecording";
import AddProgram from "./pages/Programmes/AddProgram";
import Addlesson from "./pages/Programmes/AddLesson";
import AddProgramReview from "./pages/ReviewCard/AddProgramReview";
import AddDocument from "./pages/SourceCard/AddDocument";
import EditProgram from "./pages/Programmes/EditProgram";
import AddLessonsDoc from "./pages/Programmes/AddLessonsDoc";
import AddLessonsRecording from "./pages/Programmes/AddLessonRecording";
import DeleteProgram from "./components/_dashboard/programmes/Deleteprogram";
import EditLesson from "./pages/Programmes/EditLesson";
import EditProgramReview from "./pages/ReviewCard/EditReview";
import EditProgramDocument from "./pages/SourceCard/EditProgramDocument";
import Members from "./pages/Member/Members";
import Rooms from "./pages/Room/Rooms";
import AddRooms from "./pages/Room/AddRooms";
import RecordingsList from "./pages/Recordings/RecordingsList";
import AddQuestions from "./pages/GoalStatement/AddQuestions";
import EditQuestions from "./pages/GoalStatement/EditQuestion";
import AddRecording from "./pages/Recordings/AddRecording";
import EditRoom from "./pages/Room/EditRoom";
import EditMember from "./pages/Member/EditMembers";
import EditRecording from "./pages/Recordings/EditRecording";
import EditProfile from "./pages/Profile/EditProfile";
import Calenders from "./pages/Calender/CalendarEvents";
import RecordingDetails from "./pages/Recordings/RecordingDetail";
import RoomDetail from "./pages/Room/DetailRoom";
import Groups from "./pages/Groups/Groups";
import AddGroups from "./pages/Groups/AddGroup";
import EditGroups from "./pages/Groups/EditGroup";
import GroupDetail from "./pages/Groups/DetailGroup";
import DetailQuestion from "./pages/GoalStatement/DetailQuestion";
import ListEvents from "./pages/Calender/ListEvents";
import EventDetailList from "./pages/Calender/EventDetailList";
import SupportTicket from "./pages/SupportTicket/SupportTicket";
import SupportTicketDetail from "./pages/SupportTicket/SupportTicketDetail";
import Community from "./pages/Community/Community";
import MemberGoalStatement from "./pages/MemberGoalStatement/GoalStatementMembers";
import MemberGoalstatementList from "./pages/MemberGoalStatement/MemberGoalstatementList";
import { AnswerHistory } from "./pages/MemberGoalStatement/AnswerHistory";
import { QuestionsReply } from "./pages/MemberGoalStatement/QuestionsReply";
import MemberProfile from "./pages/MemberProfile/MemberProfile";
import MemberGroupsList from "./pages/MemberProfile/MembersGroupList";
import MemberPersonalNote from "./pages/MemberProfile/MemberPersonalNote";
import ChartJs from "./pages/MemberProfile/Chart";
import Todo from "./pages/MemberProfile/Todo";
import SelfImageList from "./pages/SelfImage/SelfImageList";
import AddSelfImage from "./pages/SelfImage/AddSelfImage";
import DetailSelfImage from "./pages/SelfImage/DetailSelfImage";
import EditSelfImage from "./pages/SelfImage/EditSelfImage";
import MemberGoalStatementIncomplete from "./pages/MemberGoalStatement/GoalStatementMembersInComlete";
import MemberGoalStatementSaveAndClose from "./pages/MemberGoalStatement/GoalStatementSaveAndClose";
import AddCalendarEvents from "./pages/Calender/AddCalendarEvents";

// ----------------------------------------------------------------------
const Authentication = () => {
  console.log(localStorage.getItem("token"), "TOKEN");
  if (localStorage.getItem("token")) {
    return <Navigate to="/dashboard"> </Navigate>;
  }
  if (!localStorage.getItem("token")) {
    return <Navigate to="/login"> </Navigate>;
  }
};
export default function Router() {
  return (
    <Routes>
      <Route element={<DashboardLayout />}>
        <Route path="/" element={<Authentication />} />
        <Route path="/dashboard" element={<DashboardApp />} />
        {/* <Route path="/dashboard" element={<Community />} /> */}
        <Route path="/programmes" element={<Programmes />} />
        <Route path="/Editprofile" element={<EditProfile />} />
        <Route path="/programmes/:slug" element={<ProgrammesDetail />} />
        <Route path="/members" element={<Members />} />
        <Route
          path="/members/goalstatement/:id"
          element={<MemberGoalstatementList />}
        />
        <Route path="/members/profile/:id" element={<MemberProfile />} />
        <Route path="/members/groups/:id" element={<MemberGroupsList />} />
        <Route path="/members/to-do/:id" element={<Todo />} />
        <Route
          path="/members/personal_note/:id"
          element={<MemberPersonalNote />}
        />
        <Route path="/member-goalstatement" element={<MemberGoalStatement />} />
        <Route path="/event-add-new" element={<AddCalendarEvents />} />
        <Route
          path="/member-completed-goalstatements/:id"
          element={<MemberGoalstatementList />}
        />
        <Route
          path="/member-incomplete-goalstatements/:id"
          element={<MemberGoalstatementList />}
        />
        <Route
          path="/member-responded-goalstatements/:id"
          element={<MemberGoalstatementList />}
        />
        <Route
          path="/member-goalstatement/answer-history/:id"
          element={<AnswerHistory />}
        />
        <Route
          path="/member-goalstatement/question-reply/:id"
          element={<QuestionsReply />}
        />
        <Route path="/members/edit-members/:slug" element={<EditMember />} />
        <Route path="/links" element={<Rooms />} />
        <Route path="/links/add-link" element={<AddRooms />} />
        <Route path="/links/edit-link" element={<EditRoom />} />
        <Route path="/recordings" element={<RecordingsList />} />
        <Route path="/recordings/add-recording" element={<AddRecording />} />
        <Route path="/recordings/Edit-recording" element={<EditRecording />} />
        <Route path="/goal-statement/add-question" element={<AddQuestions />} />
        <Route path="/support-ticket" element={<SupportTicket />} />
        <Route path="/support-ticket/:id" element={<SupportTicketDetail />} />
        <Route
          path="/goal-statement/edit-question/:slug"
          element={<EditQuestions />}
        />
        <Route
          path="/goal-statement/detail-question/:slug"
          element={<DetailQuestion />}
        />
        <Route
          path="/member-completed-goalstatements"
          element={<MemberGoalStatement />}
        />
        <Route
          path="/member-incomplete-goalstatements/"
          element={<MemberGoalStatementIncomplete />}
        />
        <Route
          path="/member-responded-goalstatements"
          element={<MemberGoalStatementSaveAndClose />}
        />
        <Route
          path="programmes/lessons_detail/resources/:slug"
          element={<LessonsReSource />}
        />
        <Route
          path="/programmes/deleteProgram/:slug"
          element={<DeleteProgram />}
        />
        <Route path="/lessons/:id" element={<LessonListing />} />
        <Route path="/review/:slug" element={<ReviewCards />} />
        <Route
          path="/programmes/addReview/:slug"
          element={<AddProgramReview />}
        />
        <Route
          path="/programmes/editProgrammeReview/:programSlug/:reviewSlug"
          element={<EditProgramReview />}
        />
        <Route path="/programmes/editprogram/:id" element={<EditProgram />} />
        <Route path="/programmes/editprogram/:slug" element={<EditProgram />} />
        <Route
          path="/programmes/editresource/:programSlug/:sourceSlug"
          element={<EditProgramDocument />}
        />
        <Route path="/programmes/sources/:slug" element={<SourcesFiles />} />
        <Route path="/programmes/Addsources/:id" element={<AddDocument />} />
        <Route path="/groups/add-group" element={<AddGroups />} />
        <Route path="/groups/edit-group" element={<EditGroups />} />
        <Route path="/groups/detail-group" element={<GroupDetail />} />
        <Route path="/groups" element={<Groups />} />
        <Route
          path="/programmes/lesson_sources/:programslug/:lessonSlug"
          element={<LessonsReSource />}
        />
        <Route
          path="/lessons/addlessonsDocument/:id"
          element={<AddLessonsDoc />}
        />
        <Route path="/lessons/addlesson/:slug" element={<Addlesson />} />
        <Route
          path="/lessons/Editlesson/:lessonSlug"
          element={<EditLesson />}
        />
        <Route
          path="/lessons/addlessonsRecording/:programSlug/:lessonSlug"
          element={<AddLessonsRecording />}
        />
        <Route path="/programmes/lesson/notes/:id" element={<LessonsNotes />} />
        <Route path="/programmes/BobVideos/:id" element={<BobVideos />} />
        <Route
          path="/programmes/lessons_detail/:slug1/:slug"
          element={<LessonsDetail />}
        />
        <Route
          path="/lesson_recording_detail/:lessonSlug"
          element={<LessonsRecording />}
        />
        <Route
          path="/programmes/programm_recording_detail/48ce0817-9219-4977-9149-168e020ad288"
          element={<ProgrammRecordingDetail />}
        />
        <Route path="/programmes/addProgram" element={<AddProgram />} />
        <Route path="/goal-statement" element={<GoalStatement />} />
        <Route path="/vault" element={<Vault />} />
        <Route path="/profile" element={<Profile />} />
        <Route path="/edit-profile" element={<EditProfile />} />
        {/* <Route path="/pods" element={<Pods />} /> */}
        <Route path="/affirmations" element={<Affirmations />} />
        <Route
          path="/affirmations/affirmationList"
          element={<AffirmationList />}
        />
        <Route path="/gratitude" element={<Gratitude />} />
        <Route path="/gratitude/addgratitude" element={<AddGratitude />} />
        <Route path="/quotes" element={<Quotes />} />
        <Route path="/memories" element={<Memories />} />
        <Route
          path="/memories/memories_detail/:id"
          element={<MemoriesDetail />}
        />
        <Route path="/daily_activities" element={<DailyActivities />} />
        {/* self image  */}
        <Route path="/self-image" element={<SelfImageList />} />
        <Route path="/self-image/add-self-image" element={<AddSelfImage />} />
        <Route
          path="/self-image/edit-self-image/:slug"
          element={<EditSelfImage />}
        />
        <Route
          path="/self-image/detail-self-image/:slug"
          element={<DetailSelfImage />}
        />
        {/* self image end */}
        {/* <Route path="/calender" element={<Calender />} /> */}
        <Route path="/calender" element={<Calenders />} />
        <Route path="/calender/event-list" element={<ListEvents />} />
        <Route path="/calender/event-detail" element={<EventDetailList />} />
        {/* <Route path="/support_ticket" element={<SupportTicket />} /> */}
        <Route
          path="/recordings/detail-recording"
          element={<RecordingDetails />}
        />
        {/* <Route path="/room/detail-room" element={<RoomDetail />} /> */}
        <Route path="/links/detail-link" element={<RoomDetail />} />
      </Route>
      <Route element={<LogoOnlyLayout />}>
        <Route path="/" element={<Authentication />} />
        {/* <Route path="/register" element={<Register />} /> */}
        <Route path="404" element={<NotFound />} />
        <Route path="/login" element={<Login />} />
        <Route path="/forget_password" element={<ForgetPassword />} />
        <Route path="*" element={<NotFound />} />
      </Route>
      {/* <PublicRouteWithLayout component={Login} exact layout={LogoOnlyLayout} path="/login" />
      <PublicRouteWithLayout component={Register} exact layout={LogoOnlyLayout} path="/register" /> */}
      {/* --------------------------------------- Dashboard --------------------------------------- */}
      {/* <PrivateRouteWithLayout
        component={DashboardApp}
        exact
        layout={DashboardLayout}
        path="/dashboard/app"
      /> */}
      {/* --------------------------------------- Change Password --------------------------------------- */}
      {/* <PrivateRouteWithLayout
        component={Products}
        exact
        layout={DashboardLayout}
        path="/dashboard/products"
      /> */}
    </Routes>
  );
}
//   return useRoutes([
//     {
//       path: '/dashboard',
//       element: <DashboardLayout />,
//       children: [
//         { element: <Navigate to="/dashboard/app" replace /> },
//         { path: 'app', element: <DashboardApp /> },
//         { path: 'user', element: <User /> },
//         { path: 'products', element: <Products /> },
//         { path: 'blog', element: <Blog /> }
//       ]
//     },
//     {
//       path: '/',
//       element: <LogoOnlyLayout />,
//       children: [
//         { path: 'login', element: <Login /> },
//         { path: 'register', element: <Register /> },
//         { path: '404', element: <NotFound /> },
//         { path: '/', element: <Navigate to="/dashboard" /> },
//         { path: '*', element: <Navigate to="/404" /> }
//       ]
//     },
//     { path: '*', element: <Navigate to="/404" replace /> }
//   ]);
// }
